


























































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  deleteOffice,
  deleteOfficeBulk,
  exportOffices,
  getOffices
} from '@/api/offices'
import {
  CollectionRequestMeta,
  createFilter,
  getSortItems,
  prepareListFilters
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import {
  debounce,
  parseTime,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg, hasRoles
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'

const searchFields = [
  {
    key: 'code'
  },
  {
    key: 'name',
    options: {
      ignore_case: true,
      unaccent: true
    }
  },
  {
    key: 'main_phone'
  },
  {
    key: 'main_email',
    options: {
      ignore_case: true,
      unaccent: true
    }
  }
]

@Component({
  name: 'OfficeList',
  components: {
    Pagination,
    ListToolbar
  }
})

export default class extends Vue {
  private total = 0
  private list = []
  private listLoading = true
  private search = ''
  private selected: any = []
  private listQuery: CollectionRequestMeta = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime;
  private hasPermission = hasPermission;

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteOffice({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/office/edit/' + id)
  }

  private isUsersOffice(id: any) {
    return hasRoles(['super_admin'], UserModule.roles) ||
      hasPermission(['EDIT_OTHERS_THEME_TEMPLATES']) ||
      UserModule.officeId === id
  }

  private redirectToEditOfficePage(id: any) {
    return this.$router.push('/office/editOfficePage/' + id)
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteOfficeBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: searchFields
    })
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportOffices(this.list)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getOffices(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
    this.debounceMethod()
  }
}
